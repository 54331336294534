import { LegacyCard, LegacyStack, Modal, Text } from "@shopify/polaris";
import React from "react";

import { ModalTitle } from "../../../../../../libs/styles";
import { SUB_MODAL_DEMO_TITLE, SUB_MODAL_TITLE } from "../../../constants";
import { SubscriptionCreationEnum } from "../../../types";
import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import { SetupStoreAccess } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/store";

interface ICreateSubscriptionModal {
  modalType: SubscriptionCreationEnum;
}

export const useCreateSubscriptionModalStore = createSmartrrModal<ICreateSubscriptionModal>();

const CreateSubscriptionModal = () => {
  const { closeModal, isModalOpen, modalPayload } = useCreateSubscriptionModalStore();
  const { createDemoSellingPlanGroup, createNewSellingPlanGroup } = SetupStoreAccess.useActions();
  const setup = SetupStoreAccess.useSetup();
  const isLoading = SetupStoreAccess.useLoading();

  if (!setup || !modalPayload) {
    return <React.Fragment />;
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      title={
        <ModalTitle>
          {modalPayload.modalType === SubscriptionCreationEnum.DEMO ? SUB_MODAL_DEMO_TITLE : SUB_MODAL_TITLE}
        </ModalTitle>
      }
      primaryAction={{
        content: "Confirm",
        loading: isLoading,
        onAction() {
          if (modalPayload.modalType === SubscriptionCreationEnum.DEMO) {
            createDemoSellingPlanGroup();
          } else {
            createNewSellingPlanGroup();
          }
          closeModal();
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: closeModal,
        },
      ]}
    >
      {modalPayload.modalType === SubscriptionCreationEnum.DEMO ? (
        <React.Fragment>
          <Modal.Section>
            Smartrr will create the below demo subscription program. You&apos;ll be able to manage this
            subscription program later by visiting Subscription Programs in the left-hand navigation.
          </Modal.Section>

          <Modal.Section>
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Text variant="headingLg" as="p">
                  Subscribe & Save 10%
                </Text>
                <div>Subscribe & Save 10%</div>
              </LegacyStack>
            </LegacyCard>
          </Modal.Section>
        </React.Fragment>
      ) : (
        <Modal.Section>
          You&apos;ll be redirected to our <strong>Subscription Programs</strong> tab to create a new program from
          scratch. Once complete, you&apos;ll be prompted to return here to continue with required setup.
        </Modal.Section>
      )}
    </Modal>
  );
};

export default CreateSubscriptionModal;
