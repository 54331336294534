// @todo: all of these cards operate the same so Ideally we would break out one
// core component to support each

import { Button, LegacyCard, LegacyStack, Text } from "@shopify/polaris";
import { adminConfigRoutePrefix, adminRoutePrefix } from "@smartrr/shared/constants";
import React from "react";

import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { useFeatureAvailability } from "../../hooks/useFeatureAvailability";

const Optimizations = () => {
  const { isLoyaltyAvailable, isRetentionAvailable, isIntegrationsAvailable } = useFeatureAvailability();
  return (
    <React.Fragment>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <LegacyStack>
          <Text variant="headingMd" as="h2">
            Smartrr optimizations{" "}
          </Text>
          <Text variant="bodyMd" as="p" color="subdued">
            Subscriptions are just the beginning. Take advantage of all Smartrr&apos;s features to optimize for
            customer lifetime value.{" "}
          </Text>
        </LegacyStack>
      </div>

      <LegacyCard title="Loyalty rewards & referrals">
        <LegacyCard.Section>
          <LegacyStack>
            <Text variant="bodyMd" as="p">
              Incentivize your customers to earn and redeem rewards through different shop
              touchpoints&#8209;including referrals.
            </Text>
            <Button
              primary={isLoyaltyAvailable}
              onClick={() => {
                navigateWithShopInQuery(
                  `${adminRoutePrefix}/${isLoyaltyAvailable ? `loyalty` : `smartrr-account`}`,
                  undefined,
                  undefined,
                  true
                );
              }}
            >
              {isLoyaltyAvailable ? `Go to Loyalty settings` : `💎 Upgrade to Excel`}
            </Button>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>

      <LegacyCard title="Theming">
        <LegacyCard.Section>
          <LegacyStack>
            <p>
              Customize the look and feel of your site&apos;s Customer Account Portal with our easy theming
              settings—or add CSS overrides. Bonus: use Translation settings to made copy and language overrides.
            </p>
            <Button
              onClick={() =>
                navigateWithShopInQuery(`${adminConfigRoutePrefix}/theming`, undefined, undefined, true)
              }
              primary
            >
              Go to Theming settings
            </Button>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>

      <LegacyCard title="Subscription shipping rules">
        <LegacyCard.Section>
          <LegacyStack>
            <p>
              Want your subscription orders to have free or different shipping profiles than non-subscription
              products? Setup dedicated subscription shipping rules within Smartrr.
            </p>
            <Button
              onClick={() =>
                navigateWithShopInQuery(`${adminConfigRoutePrefix}/delivery-profiles`, undefined, undefined, true)
              }
              primary
            >
              Go to Shipping settings
            </Button>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>

      <LegacyCard title="Retention">
        <LegacyCard.Section>
          <LegacyStack vertical>
            <p>
              Reduce subscription churn by defining a retention options for up to eight possible cancellation
              reasons.
            </p>
            <Button
              onClick={() => {
                navigateWithShopInQuery(
                  `${isRetentionAvailable ? adminConfigRoutePrefix : adminRoutePrefix}/${
                    isRetentionAvailable ? `churn-prevention` : `smartrr-account`
                  }`,
                  undefined,
                  undefined,
                  true
                );
              }}
              primary={isRetentionAvailable}
            >
              {isRetentionAvailable ? `Go to Retention settings` : `📈 Upgrade to Grow`}
            </Button>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>

      <LegacyCard title="Integrations">
        <LegacyCard.Section>
          <LegacyStack vertical>
            <p>Connect with Klaviyo, Attentive, Postscript, Gorgais, Slack, & more.</p>
            <Button
              onClick={() => {
                navigateWithShopInQuery(
                  `${isIntegrationsAvailable ? adminConfigRoutePrefix : adminRoutePrefix}/${
                    isIntegrationsAvailable ? `integrations` : `smartrr-account`
                  }`,
                  undefined,
                  undefined,
                  true
                );
              }}
              primary={isIntegrationsAvailable}
            >
              {isIntegrationsAvailable ? `Go to Integrations settings` : `📈 Upgrade to Grow`}
            </Button>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
    </React.Fragment>
  );
};

export default Optimizations;
