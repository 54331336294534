import { Banner, LegacyStack, Text } from "@shopify/polaris";
import React from "react";

import SubscriptionProductsCard from "./libs/components/Cards/SubscriptionProductsCard";
import SubscriptionProgramCard from "./libs/components/Cards/SubscriptionProgramCard";
import HelpDocsLink from "./libs/components/HelpDocsLink";
import Optimizations from "./libs/components/Optimizations";
import { SUBSCRIPTION_SET_UP_TITLE } from "./libs/constants";
import { useSubscriptionEligibility } from "./libs/hooks/useSubscriptionEligibility";
import { TabHeader } from "../../libs/styles";
import { SetupStoreAccess } from "../../libs/store";
import { CenterSpinner } from "@vendor-app/app/AdminRoute/components/elements/CenterSpinner";

export const SubscriptionSetup = ({
  setSelectedTab,
}: {
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const setup = SetupStoreAccess.useSetup();

  if (!setup) {
    return <React.Fragment />;
  }

  return <SubscriptionSetupInner setSelectedTab={setSelectedTab} />;
};

const SubscriptionSetupInner = ({
  setSelectedTab,
}: {
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const isLoading = SetupStoreAccess.useLoading();
  const { eligibleForSubscriptions } = useSubscriptionEligibility();

  if (isLoading) {
    return <CenterSpinner />;
  }

  return (
    <LegacyStack vertical>
      <TabHeader>
        <Text variant="headingLg" as="h2" fontWeight="semibold">
          {SUBSCRIPTION_SET_UP_TITLE}{" "}
        </Text>
      </TabHeader>
      {!eligibleForSubscriptions && (
        <Banner title="Warning" status="warning">
          <Text variant="bodyMd" as="p" fontWeight="regular">
            Smartrr has detected that your shop is currently ineligible to create subscriptions through Shopify.
            Please reference Shopify&apos;s full list of{" "}
            <a
              data-testid="subscription-set-up-ineligible-link"
              rel="noreferrer"
              href="https://help.shopify.com/en/manual/products/subscriptions/setup#eligibility-requirements"
              style={{ textDecoration: "underline" }}
            >
              subscription eligibility requirements.
            </a>
          </Text>
        </Banner>
      )}
      <SubscriptionProgramCard />
      <SubscriptionProductsCard setSelectedTab={setSelectedTab} />
      <HelpDocsLink />
      <Optimizations />
    </LegacyStack>
  );
};
