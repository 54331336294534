import { Tabs, Page, TabProps } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";

import { BADGE_BASE_TEXT, ONSITE_SETUP_TAB_LABEL, SUBSCRIPTION_SETUP_TAB_LABEL } from "./libs/constants";
import { SetupTabsEnum } from "./libs/types";
import { OnsiteSetup } from "./Tabs/OnSiteSetup";
import { SubscriptionSetup } from "./Tabs/SubscriptionSetup";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { OnSiteSetupNew } from "./Tabs/OnSiteSetupNew";
import { SetupStoreAccess } from "./libs/store";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import useSetCounts from "./libs/hooks/useSetCounts";

export function AdminSetupRoute(): JSX.Element {
  const [selectedTab, setSelectedTab] = useState<number>(SetupTabsEnum.SUBSCRIPTION);
  const { addToast } = useToast();
  const { initialize } = SetupStoreAccess.useActions();
  const setup = SetupStoreAccess.useSetup()!;
  const { subscriptionSetupCount, onSiteSetupCount } = useSetCounts(setup);

  const errors = SetupStoreAccess.useErrors();

  const { updatedSetupSteps } = useSmartrrFlags();

  const handleTabChange = useCallback(selectedTabIndex => setSelectedTab(selectedTabIndex), []);

  const RenderTabsSwitch = (selectedTab: SetupTabsEnum) =>
    useMemo(() => {
      switch (selectedTab) {
        case SetupTabsEnum.SUBSCRIPTION: {
          return <SubscriptionSetup setSelectedTab={setSelectedTab} />;
        }
        case SetupTabsEnum.ONSITE: {
          if (updatedSetupSteps) {
            return <OnSiteSetupNew />;
          }
          return <OnsiteSetup />;
        }
        default: {
          return null;
        }
      }
    }, [selectedTab, updatedSetupSteps]);

  const subscriptionSetupTabCount = useMemo(
    () => `${subscriptionSetupCount}${BADGE_BASE_TEXT}`,
    [subscriptionSetupCount]
  );

  const AdminSetUpRouteTabs: TabProps[] = useMemo(
    () => [
      {
        id: "subscription-setup",
        content: SUBSCRIPTION_SETUP_TAB_LABEL,
        badge: subscriptionSetupTabCount,
        panelID: "subs-setup-content",
      },
      {
        id: "on-site-setup",
        content: ONSITE_SETUP_TAB_LABEL,
        // Remove condition once `updated-setup-steps` / `updatedSetupSteps` is archived
        ...(updatedSetupSteps ? undefined : { badge: `${onSiteSetupCount}${BADGE_BASE_TEXT}` }),
        panelID: "on-site-setup-content",
      },
    ],
    [subscriptionSetupTabCount, onSiteSetupCount, updatedSetupSteps]
  );

  // Remove condition once `updated-setup-steps` / `updatedSetupSteps` is archived
  useEffect(() => {
    initialize(true);
  }, []);

  useEffect(() => {
    if (errors.showError && errors.message) {
      addToast(errors.message, true);
    }
  }, [updatedSetupSteps, errors]);

  return (
    <Page narrowWidth>
      <Tabs tabs={AdminSetUpRouteTabs} selected={selectedTab} onSelect={handleTabChange} fitted>
        <div data-testid="render-tabs-container">{RenderTabsSwitch(selectedTab)}</div>
      </Tabs>
    </Page>
  );
}
