import { ApolloClient } from "@apollo/client/main.cjs";
import {
  mutationShopifyProductJoinSellingPlanGroups,
  mutationShopifyProductLeaveSellingPlanGroups,
  mutationShopifyProductVariantJoinSellingPlanGroups,
  mutationShopifyProductVariantLeaveSellingPlanGroups,
} from "@smartrr/shared/shopifyGraphQL/sellingPlans";

export async function addProductsToGroup(
  client: ApolloClient<object>,
  groupId: string,
  productIds: string[]
): Promise<void> {
  for (const productId of productIds) {
    await mutationShopifyProductJoinSellingPlanGroups(productId, [groupId], client);
  }
}

export async function addVariantsToGroup(
  client: ApolloClient<object>,
  groupId: string,
  variantIds: string[]
): Promise<void> {
  for (const variantId of variantIds) {
    await mutationShopifyProductVariantJoinSellingPlanGroups(variantId, [groupId], client);
  }
}

export async function removeProductsFromGroup(
  client: ApolloClient<object>,
  groupId: string,
  productIds: string[]
) {
  for (const productId of productIds) {
    await mutationShopifyProductLeaveSellingPlanGroups(productId, [groupId], client);
  }
}

export async function removeVariantsFromGroup(
  client: ApolloClient<object>,
  groupId: string,
  variantIds: string[]
) {
  for (const variantId of variantIds) {
    await mutationShopifyProductVariantLeaveSellingPlanGroups(variantId, [groupId], client);
  }
}
