import { Button, Icon, LegacyCard, LegacyStack, Link, Text } from "@shopify/polaris";
import { CircleTickMajor } from "@shopify/polaris-icons";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import React from "react";

import { SUB_PROGRAM_URL } from "../../../../../../libs/constants";
import { SetupIconContainer, SetupIconWrapper } from "../../../../../../libs/styles";
import { SetUpAProgramCardTitle } from "../../../constants";

import { SubscriptionCreationEnum } from "../../../types";
import CreateSubscriptionModal, { useCreateSubscriptionModalStore } from "../../Modals/CreateSubscriptionModal";
import { SetupStoreAccess } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/store";

const SubscriptionProgramCard = () => {
  const setup = SetupStoreAccess.useSetup();

  const { openModal } = useCreateSubscriptionModalStore();

  if (!setup) {
    return <React.Fragment />;
  }

  const isDemoProgramCreated =
    !!setup.subscriptionSetup?.sellingPlan?.demo && !!setup.subscriptionSetup?.sellingPlan?.completed;

  return (
    <LegacyCard title={SetUpAProgramCardTitle}>
      <LegacyCard.Section>
        <Text variant="bodyMd" as="p">
          Subscription programs control which products are available for subscription purchase. Select if you
          would Smartrr to create a demo program or if you would like to create your own program from scratch
        </Text>{" "}
        &nbsp;
        <LegacyStack vertical>
          <LegacyStack>
            <Button
              size="slim"
              onClick={() => {
                openModal({ modalType: SubscriptionCreationEnum.DEMO });
              }}
            >
              Use a demo program
            </Button>
            {!!isDemoProgramCreated && (
              <SetupIconContainer>
                <SetupIconWrapper>
                  <Icon source={CircleTickMajor} color="base" />
                </SetupIconWrapper>
                <Text
                  variant="bodyMd"
                  as="span"
                  color="subdued"
                >{`You created a demo program on ${ISODateString.fromString(
                  setup.subscriptionSetup.sellingPlan.date
                ).toLocaleString()}`}</Text>
              </SetupIconContainer>
            )}
          </LegacyStack>
          <LegacyStack>
            <Button
              size="slim"
              onClick={() => {
                openModal({ modalType: SubscriptionCreationEnum.NEW });
              }}
              primary
            >
              Create my own program
            </Button>
            {!setup.subscriptionSetup?.sellingPlan?.demo && !!setup.subscriptionSetup?.sellingPlan?.completed && (
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                <SetupIconWrapper>
                  <Icon source={CircleTickMajor} color="base" />
                </SetupIconWrapper>
                <Text variant="bodyMd" as="span">
                  <Link external url={SUB_PROGRAM_URL}>
                    Manage subscription programs
                  </Link>
                </Text>
              </div>
            )}
          </LegacyStack>
        </LegacyStack>
      </LegacyCard.Section>
      <CreateSubscriptionModal />
    </LegacyCard>
  );
};

export default SubscriptionProgramCard;
