import { Button, Card, Text, Tooltip, VerticalStack } from "@shopify/polaris";
import { AddWidgetButton } from "../AddWidgetButton";
import { ThemeSelect } from "../ThemeSelect";
import { useCallback, useMemo, useState } from "react";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { parseShopName } from "@smartrr/shared/utils/parseShopName";
import { useThemeCustomizationUrl } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/utils";
import { SetupStoreAccess } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/store";
import { ContactSupportBanner } from "../ContactSupportBanner";
import { DisabledButton } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/styles";
import { ExternalMinor } from "@shopify/polaris-icons";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { useIsSuperUserSelector } from "@vendor-app/app/_state/reducers/auth";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { isEmpty } from "lodash";

const AddAppMetafieldsToShopButton = () => {
  const flags = useSmartrrFlags();
  const isSuperUser = useIsSuperUserSelector();

  if (!flags.customerPortalExtension || !isSuperUser) {
    return null;
  }

  return (
    <DisabledButton>
      <Button
        icon={ExternalMinor}
        primary
        onClick={async () => {
          await typedFrontendVendorApi.postReq("/extensions/update-shop");
        }}
        size="slim"
      >
        Update shop metadata
      </Button>
    </DisabledButton>
  );
};

export const AddCustomerPortalWidgetCard = () => {
  const [isThemeValid, setIsThemeValid] = useState<boolean>(true);
  const org = useActiveOrganizationSelector();
  const { purchasables, isLoading: isPurchasablesLoading } = useSmartrrVendorSelector(
    state => state.purchasables
  );
  const themes = SetupStoreAccess.useThemes();
  const { validateTheme } = SetupStoreAccess.useActions();
  const { shopName } = parseShopName(org?.myShopifyDomain);
  const [themeId, setThemeId] = useState<string>("");

  // Linking merchants to their shop's account page (assuming they're logged in)
  const themeCustomizeLink = useThemeCustomizationUrl(shopName, themeId, "/account");

  const disabled = useMemo(
    () => !isThemeValid || !themes.length || !themeId || isEmpty(themeCustomizeLink),
    [isThemeValid, themes.length, themeId]
  );

  const onSelect = useCallback(async (id: string) => {
    const isValid = await validateTheme(id, "templates/customers/account.json");
    setIsThemeValid(isValid);
    setThemeId(id);
  }, []);
  return (
    <Card>
      <VerticalStack gap={"3"}>
        <Text as="h2" variant="headingMd">
          2. Add customer portal widget
        </Text>
        {!isThemeValid && <ContactSupportBanner widgetType="customer portal" />}
        <VerticalStack gap={"4"}>
          <Text as="p">
            Enables customers to manage their subscriptions using Smartrr&apos;s customer portal. Customize the
            portal via &quot;Theming&quot; from the left hand navigation.
          </Text>
          <ThemeSelect
            onSelect={onSelect}
            helpText="Make sure to log in and add the widget on the account page, not the log-in page"
          />
          {!isPurchasablesLoading && !purchasables.length ? (
            <Tooltip
              content="There are no products available in the store. Product template theme customization is disabled."
              dismissOnMouseOut
            >
              <AddWidgetButton url={themeCustomizeLink} disabled={disabled} />
            </Tooltip>
          ) : (
            <AddWidgetButton url={themeCustomizeLink} disabled={disabled} />
          )}
          <AddAppMetafieldsToShopButton />
        </VerticalStack>
      </VerticalStack>
    </Card>
  );
};
