import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { isFeatureAvailable } from "../../../../../../components/authorization/featureAccess";

export const useFeatureAvailability = () => {
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const user = useSmartrrVendorSelector(state => state.auth.user);

  const isLoyaltyAvailable = isFeatureAvailable(FeatureEnum.LOYALTY, user, activePlan);
  const isRetentionAvailable = isFeatureAvailable(FeatureEnum.RETENTION, user, activePlan);
  const isIntegrationsAvailable = isFeatureAvailable(FeatureEnum.INTEGRATIONS, user, activePlan);

  return { isLoyaltyAvailable, isRetentionAvailable, isIntegrationsAvailable };
};

export default useFeatureAvailability;
